import SettingRepository from "../../api/repositories/settingRepository";
import LicenseRepository from "../../api/repositories/licenseRepository";
import licenseRepository from "../../api/repositories/licenseRepository";

import { addHours } from "date-fns";
import CompanyRepository from "@/api/repositories/companyRepository";
import vuetify from "../../plugins/vuetify";
import RestrictHelper, { FEATURES } from "../../_helpers/RestrictHelper";

export const configuration = {
  namespaced: true,
  state: {
    darkmode: false,
    SO_API_BASE_URL: null,
    userSettings: {
      theme: "Scientific",
      toolbarTransparent: true,
      stickySideMenu: true,
    },
    globalSettings: {},
    license: {},
    companyFields: [],
    companySettings: {},
    serverMeta: {},
    nodesLeftForCompany: 9999,
    expiresIn: 999,
    lastShownExpiery: undefined,
    showLicenseNotification: false,
    showLicenseExpiery: false,
    canCreateCompany: false,
    canCreateGateway: false,
    canCreateNode: false,
  },

  actions: {
    async uploadWhiteLabelImage(_, image) {
      await CompanyRepository.postWhiteLabelImage(image)
        .then(() => undefined)
        .catch(() => undefined);
    },

    async getNodesLeftForCompany({ commit, rootState }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;
      await LicenseRepository.nodesLeftForCompany(user, company)
        .then((d) => commit("SET_NODES_LEFT_COMPANY", d.data))
        .catch(() => commit("SET_NODES_LEFT_COMPANY", 9999));
    },

    setLicenseNotification({ commit }, val) {
      commit("SHOW_LICENSE_NOTIFICATION", val);
    },

    setDarkmode({ commit }, message) {
      if (typeof message == "boolean") {
        commit("SET_DARKMODE", message);
      }
    },

    async getServerMeta({ commit, rootState }) {
      var result = await licenseRepository
        .getLicenseServerMeta(rootState.users.currentUser)
        .then((d) => {
          commit("SET_SERVER_META", d.data);
          return true;
        })
        .catch(() => false);
      if (!result) console.error("Could not fetch server meta");
    },

    async getBaseUrl({ commit, rootState }) {
      if (
        rootState.configuration.SO_API_BASE_URL == undefined ||
        rootState.configuration.SO_API_BASE_URL == null
      ) {
        try {
          const envConfig = await fetch("/environmentConfig.json");
          var envJson = await envConfig.json();
          commit("SET_BASE_URL", envJson.SO_API_BASE_URL);
        } catch (exception) {
          commit("SET_BASE_URL", process.env.VUE_APP_SO_API_BASE_URL);
        }
      }
    },

    async getLicenseTerms({ commit, rootState }, force = false) {
      if (
        !force &&
        rootState.configuration.license &&
        Object.keys(rootState.configuration.license).length !== 0
      ) {
        return;
      }

      // If the base url is not set we cannot get the terms
      // This will probably be resolved during existing requests.
      if (rootState.configuration.SO_API_BASE_URL == null) return;

      await LicenseRepository.getTerms().then((res) =>
        commit("SET_LICENSE", res.data)
      );
    },

    async getUserSettings({ commit, rootState, dispatch }) {
      var user = rootState.users.currentUser;
      if (user === undefined || user === null) return;

      await SettingRepository.get().then((data) =>
        commit("SET_USER_SETTINGS", data.data)
      );

      await dispatch("users/getUserPermissionsByCurrentUser", null, {
        root: true,
      });
    },

    async setUserSettings({ dispatch }, payload) {
      await SettingRepository.set(payload).then(() =>
        dispatch("getUserSettings")
      );
    },

    async getGlobalSettings({ commit, rootState }) {
      var user = rootState.users.currentUser;
      if (user === undefined || user === null) return;

      await SettingRepository.getGlobal().then((data) => {
        commit("SET_GLOBAL_SETTINGS", data.data);
      });
    },

    async setGlobalSettings({ dispatch }, payload) {
      await SettingRepository.setGlobal(payload).then(() =>
        dispatch("getGlobalSettings")
      );
    },

    async setCompanySettings({ dispatch }, payload) {
      await SettingRepository.setCompany(payload).then(() =>
        dispatch("getCompanySettings")
      );
    },

    async getCompanySettings({ dispatch, commit }) {
      // Make sure to reset the fields and settings before
      commit("SET_COMPANY_FIELDS", []);
      commit("SET_COMPANY", {});
      await SettingRepository.getCompany()
        .then((data) => {
          commit("SET_COMPANY", data.data);
        })
        .catch(() => commit("SET_COMPANY", {}));

      // Get the fields
      await dispatch("getCompanyFields");
    },

    async getCompanyFields({ commit }) {
      await SettingRepository.getCompanyFields().then((data) => {
        commit("SET_COMPANY_FIELDS", data.data);
      });
    },

    setCompanySettingLocal({ commit }, { key, value }) {
      commit("SET_COMPANY_SETTING", { key, value });
    },

    async getCanCreateBools({ commit, rootState }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany.companyId;

      await LicenseRepository.canCreate(user, company)
        .then((d) => commit("SET_CAN_CREATE", d.data))
        .catch(() => commit("SET_CAN_CREATE", undefined));
    },
  },

  mutations: {
    SET_CAN_CREATE(state, data) {
      state.canCreateCompany = data?.canCreateCompany ?? false;
      state.canCreateGateway = data?.canCreateGateway ?? false;
      state.canCreateNode = data?.canCreateNode ?? false;
      state.expiresIn = data?.expiresIn ?? 999;
      state.showLicenseExpiery = false;
      if (
        state.expiresIn <= 30 &&
        (state.lastShownExpiery === undefined ||
          state.lastShownExpiery < new Date())
      ) {
        state.lastShownExpiery = addHours(new Date(), 24);
        state.showLicenseExpiery = true;
      }
    },

    SET_SERVER_META(state, data) {
      state.serverMeta = data;
    },

    SET_DARKMODE(state, message) {
      state.darkmode = message;
    },
    SET_BASE_URL(state, url) {
      state.SO_API_BASE_URL = url;
    },

    SET_USER_SETTINGS(state, payload) {
      for (var i in Object.keys(payload)) {
        let key = Object.keys(payload)[i];
        if (payload[key] == "true" || payload[key] == "false")
          payload[key] = payload[key] == "true" ? true : false;
      }

      state.userSettings = payload;
    },

    SET_LICENSE(state, payload) {
      state.license = payload;
    },

    SET_GLOBAL_SETTINGS(state, payload) {
      state.globalSettings = payload;
    },

    SET_COMPANY(state, payload) {
      state.companySettings = payload;
    },

    SET_COMPANY_FIELDS(state, payload) {
      state.companyFields = payload;
    },

    SET_COMPANY_SETTING(state, keyValue) {
      state.companySettings[keyValue.key] = keyValue.value;
    },

    SHOW_LICENSE_NOTIFICATION(state, val) {
      state.showLicenseNotification = val;
    },

    SET_NODES_LEFT_COMPANY(state, val) {
      state.nodesLeftForCompany = val;
    },
  },

  getters: {
    license(state) {
      return state.license;
    },

    documentTitle(state) {
      let title = state.companySettings["COMPANY_BRANDING_TITLE"];
      if (title == undefined || title == "")
        return state.globalSettings?.branding ?? "Sensor Online";

      return title;
    },

    whiteLabelLogoForce(state) {
      let wl = state.companySettings["WHITE_LABEL_IMAGE"];
      if (wl === undefined || wl === null || wl === "") return undefined;

      return wl;
    },

    logoPath(state) {
      try {
        if (
          !RestrictHelper.methods.canAccessFeature(
            FEATURES.PER_COMPANY_BRANDING
          )
        ) {
          if (
            state.license.WHITE_LABEL != undefined &&
            state.license.WHITE_LABEL != null &&
            state.license.WHITE_LABEL.toLowerCase() == "true"
          )
            return "so-white-label.png";

          return vuetify?.framework?.theme?.isDark
            ? "/sensor-online-dark.svg"
            : "sensor-online.svg";
        }

        let wl = state.companySettings["WHITE_LABEL_IMAGE"];
        if (wl === undefined || wl === null || wl === "") wl = undefined;

        if (
          state.license.WHITE_LABEL != undefined &&
          state.license.WHITE_LABEL != null &&
          state.license.WHITE_LABEL.toLowerCase() == "true"
        ) {
          if (wl === undefined) return "so-white-label.png";

          return `${state.SO_API_BASE_URL ?? ""}/api/image/${wl}`;
        }

        const baseImage = vuetify?.framework?.theme?.isDark
          ? "/sensor-online-dark.svg"
          : "sensor-online.svg";
        return wl === undefined
          ? baseImage
          : `${state.SO_API_BASE_URL ?? ""}/api/image/${wl}`;
      } catch {
        return "sensor-online.svg";
      }
    },

    getCompanySetting: (state) => (key) => {
      return state.companySettings[key];
    },

    getCompanyCoordinates: (state) => {
      try {
        return JSON.parse(state.companySettings["COORDINATES"]);
      } catch (e) {
        console.warn("Default company coordinates returned json parse error");
        return { latitude: null, longitude: null };
      }
    },
  },
};
