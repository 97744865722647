export default {
  routes: {
    home: "Hem",
    login: "Login",
    register: "Registrera",
    registerCompany: "Nytt Konto",
    logout: "Logout",

    company: "Företag",
    dashboard: "Dashboard",
    application: "Applikationer",
    alarm: "Larmhantering",

    user: "Användare",
    tagdata: "Data",
    tag: "Noder",
    script: "Script-motor",
    report: "Rapport",
    contact: "Kontakt",
    apiRef: "API-Dokumentation",
    shop: "Köp noder",
    manual: "Manual",
    settings: "Inställningar",
    signalTest: "Fälttestare",
    gateway: "Gateways",
    news: "Nyheter",
    roles: "Roller",

    userEdit: "Konto",

    superadmin: "Admin",
  },

  integrations: {
    title: "Integrationer",
    chargeAmps: {
      fields: {
        apiKey: "API Key",
        organizationId: "Orginisations ID",
        useRfid: "Använd RFID som identifierare",
      },

      organizationIdDescription: "Orginsiations ID för ChargeAmps",
      useRfidDescription:
        "Använd RFID som identifierare för att göra det möjligt att katigoriesera datan per användare",
      apiKeyDescription:
        "API Key för ChargeAmps som distributeras av ChargeAmps support",
    },
  },

  actions: {
    title: "Actions",
    toolbar: "Actions",
    create: "Skapa en Action",
    edit: "Redigera Action",
    delete: "Tabort Action",
    deleteError: "Kunde inte tabort Action",
    deleteSuccess: "Borttagen",
    notify: "Notifierings-metoder",
    noCooldownActive: "Inga aktiva cooldowns",
    onCooldown: "Aktiv Cooldown",
    noCooldownDescription: "Det är inga aktiva cooldowns att agera på",
    resetCooldownActionDescription:
      "Återställ cooldown för denna action, detta gör det möjligt att trigga actionen igen",
    resetCooldown: "Återställ cooldown",
    resetAllCooldown: "Återställ alla cooldowns",

    config: {
      emailTitleConfigDescription:
        "Email titel template gör det möjligt att anpassa titeln på e-posten (Exempel för att inkludera nodnamn i titeln: 'Noder {node_name} är offline')",
      availableTitleVariables: "Tillgängliga variabler",
      variables: "{node_name}, {node_deveui} och {node_name_and_deveui}",
    },

    fields: {
      title: "Titel",
      description: "Beskrivning",
      eventType: "Event typ",
      actionTypesString: "Action typ",
      sms: "Sms",
      email: "Email",
      cooldownOnAction: "Cooldown på action",
      cooldownMinutes: "Cooldown i minutes",
      cooldownUntil: "Cooldown till",
      manualCooldown: "Manuell bekräftelse",
      duration: "Varaktighet",
      emailTitleTemplate: "Email titel template",
      isOnCooldown: "Cooldown",
      identifier: "Identifierare",
    },

    hints: {
      duration: "Varaktighet i minuter för det valda eventet",
      manualCooldown: "Manuell bekräftelse krävs för att trigga ett nytt event",
    },

    details: {
      edit: "Redigera Action",
      cooldowns: "Actions på cooldown",
      history: "Action historik",
    },

    resources: {
      title: "Resurser",
      create: "Skapa en resurs",
      edit: "Redigera resurs",
      delete: "Tabort resurs",
      deleteError: "Kunde inte tabort resurs",
      deleteSuccess: "Borttagen",
      selectResource: "Välj resurs",
      selectAll: "Välj alla",

      fields: {},
    },

    actions: {
      title: "Actions",
      description: "Actions är vad som händer när ett event är triggat",
      sms: "SMS",
      email: "Email",
      addToAction: "Lägg till i Action",
    },

    errors: {
      noActionIdFound: "Ingen Action med det ID som angavs hittades",
    },

    eventTypes: {
      0: "Data Ingest Error",
      1: "Node Offline",
      2: "Lägg till Node till Action",
    },
  },

  opc: {
    title: "OPC-UA",

    server: {
      autocomplete: "Välj server",
      title: "OPC-UA Servrar",
      fields: {
        opcServerId: "OPC Server ID",
        serverUrl: "Server URL",
        useUsernameAndPassword: "Använd Användarnamn och Lösenord",
        updatedAt: "Uppdaterad",
        createdAt: "Skapad",
      },
    },

    node: {
      fields: {
        serverId: "Server",
        key: "Nyckel",
        nodeId: "NodeID",
      },

      hints: {
        nodeId: "Exempel, ns=1;i=1001",
      },
    },
  },

  modbus: {
    title: "Modbus",

    master: {
      title: "Modbus Masters",
      create: "Lägg till en ny Master",
      tryReconnect: "Försök att återansluta",

      fields: {
        modbusMasterId: "Master ID",
        name: "Benämning",
        host: "IP",
        port: "Port",
        status: "Status",
      },
    },

    register: {
      title: "Modbus Register",
      create: "Lägg till ett nytt register",
      poll: "Poll registers",
      polledValue: "Hämtat värde",

      fields: {
        modbusRegisterId: "Register ID",
        modbusMasterId: "Master ID",
        master: "Master",
        slave: "Slav ID",
        nodeId: "Nod ID",
        key: "Sensor nyckel",
        register: "Register",
        pollInterval: "Poll interval (Sekunder)",
        registerType: "Register-typ",
        valueType: "Värde-typ",
        action: "Pull/Push",
        byteOrder: "Byte-order",
        createdAt: "Skapad",
        updatedAt: "Uppdaterad",
      },

      valueTypes: {
        0: "FLOAT",
        1: "DOUBLE",
        2: "INT16",
        3: "UINT16",
        4: "INT32",
        5: "UINT32",
        6: "BOOL",
      },

      types: {
        0: "Holding Register",
        1: "Coil",
        2: "Analog Input",
        3: "Digital Input",
      },

      byteOrders: {
        0: "ABCD",
        1: "DCBA (Default)",
        2: "BADC",
        3: "CDAB",
      },
    },

    validation: {
      required: "Detta fält krävs",
      slaveMinVal: "SlavID måste vara större än 1",
      slaveMaxVal: "SlavID måste vara mindre än 255",
      registerMaxVal: "Register måste vara mindre än 67000",
      registerMinVal: "Register måste vara större eller lika med 0",
      pollIntervalMin: "Poll Interval kan inte vara mindre än 60 sekunder",
      registerAllreadyReadFrom:
        "Detta register används redan av någon av dina andra register",
    },
  },

  spotIo: {
    fields: {
      key: "SpotIO Nykel",
    },
    validation: {
      spotIoKeyRequired: "'SpotIO Nykel' måste vara ifyld",
      spotIoKeyUnique: "'SpotIO Nykel' måste vara unik för Noden",
    },
  },

  colorPicker: {
    colorForExpressionLabel: "Färg för värde",

    so_style_meta_title_text: "Titel textfärg",
    so_style_meta_title_background: "Titel bakgrundsfärg",
    so_style_meta_content_title: "Innehåll textfärg",
    so_style_meta_content_background: "Innehåll bakgrundsfärg",
    so_style_meta_fill_level_color: "Fyllnads-färg",
    so_style_meta_color_for_expression: "Färg för värde",
    so_style_meta_action_for_content: "Färg för värde",

    iconColor: "Ikon färg",
    backgroundColor: "Bakgrundsfärg",
  },

  widget: {
    weather: {
      pickTitle: "Position för SMHI väder-data",
    },

    form: {
      step1: "Widget",
      step2: "Slots / Keys",
      step3: "Attributes",
    },
  },

  slot: {
    addHint: "Lägg till en Nod Slot",
    resetSlots: "Återställ alla slots",
    slotIndex: "Slot Index",
    priorityTitle: "Slot prioritet",
    priorityDescription:
      "Slot prioriteten används för att välja vilka Sensorer som ska visas först",
  },

  templateDashboard: {
    title: "Dashboard Mallar",
    deleteError: "Kunde inte tabort template",
    deleteSuccess: "Borttagen",

    saveDashboard: "Spara",

    defaults: {
      name: "Ny Mall Dashboard",
    },

    fields: {
      name: "Namn",
      dashboardType: "Typ",
      createdAt: "Skapad",
      updatedAt: "Uppdaterad",
    },

    addSlot: "Lägg till Nod-Slot",
    numberOfAvailableSlots: "Antal Nod-Slots: {num}",

    dashboardTypeTitle: "Dashboard typ",
    dashboardType: {
      0: "Full Dashboard",
      1: "Sido / Kart Dashboard",
      2: "Nod Dashboard",
    },

    slots: {},
  },

  camOnline: {
    servers: "Cam-Online Servers",
    couldNotFetchServers: "Kunde inte hämta Cam-Online Servrar",
    couldNotCreateServer: "Något gick fel när Cam-Online Server lades till",
    couldNotDeleteServer: "Något gick fel vid borttagande av Cam-Online Server",
    serverSelect: "Välj Cam-Online Server",
    streamSelect: "Välj en kamera",
    createDialogTitle: "Lägg till en ny server",
    tabTitle: "Cam-Online",
    fields: {
      url: "Url (Ex: https://portal.cam-online.se)",
      accessToken: "AccessToken",
      isGlobal: "Global Server",
    },
  },

  roles: {
    toolbarTitle: "Roller",
    permissionCounts: "Rättigheter",

    missingRecommendedTitle: "Alla rekommenderade rättigheter ej valda.",
    missingRecommendedButton: "Klicka här för att lägga till saknade",

    pickPermissionsTitle: "Rättighetsväljare",
    pickPermissionsDesc:
      "Du kan välja en basroll att basera den nya rollen på nedan eller börja helt från noll. Om du väljer rättigheter som behöver andra kommer du rekommenderas dessa. De visas i listan med <b class='yellow--text text--darken-3'>Orange</b> färg.",

    fields: {
      name: "Namn",
      permissionCount: "Antal Rättigheter",
      isDefault: "Är Basroll",
      isSuper: "Är Admin",
    },

    layerTypes: {
      0: "Grundrättigheter För Gränssnittet",
      1: "Huvudrättigheter För Gränssnittet",
      2: "Rättigheter Till API",
    },
  },

  eventhub: {
    waiting: "Väntar på nya meddelanden...",

    copySuccess: "Payload är kopierad",
    copyError: "Kunde inte kopiera payload",

    events: {
      data: "Ny data",
      endpoint: "ENDPOINT",
    },
  },

  downlinks: {
    editorTitle: "Downlinks",

    validation: {
      port: "Port måste vara över 0",
    },
  },

  conditionTree: {
    selectValueTypeTitle: "Välj värdes typ",
    selectType: "Välj typ",
    selectValue: "Välj värde",
    chooseSensor: "Välj Sensor",

    textInputPlaceholder: "Text Värde",
    numberInputPlaceholder: "Nummer Värde",

    types: {
      0: "Sensor Value",
      1: "Text",
      2: "Number",
      3: "Time",
      4: "Inget Värde",
    },

    ops: {
      greater: "Större än",
      less: "Mindre än",
      equal: "Samma som",
      greaterOrEqual: "Större eller lika än",
      lessOrEqual: "Mindre eller lika än",
      notEqual: "Inte",
    },
  },

  accessToken: {
    title: "Åtkomsttoken",
    newToken: "Skapa en ny token",
    cardNewTitle: "Ny token",
    cardTitle: "Åtkomsttoken",
    fields: {
      id: "Id",
      label: "Titel",
      token: "Token",
      isCompany: "Företagstoken",
    },
  },

  news: {
    cannotGetNews: "Kunde inte hämta nyheterna från Nodeledge AB.",
  },

  gateway: {
    toolbar: "Gateways",
    toolbarTitle: "Gateways",
    bestGw: "Bästa gateway",

    pickLocationButton: "Ange position",
    fields: {
      name: "Namn",
      macAddress: "GatewayEUI",
      latitude: "Latitude",
      longitude: "Longitude",
      createdAt: "Skapad",
      lastSeen: "Senast sedd",
    },
  },

  signalTest: {
    toolbarTitle: "Fälttestare",
    startTest: "Starta ett nytt test",
    endTest: "Avsluta test",
    peekTest: "Titta på test",
    createToolbar: "Starta ett nytt test",
    rawData: "Rådata",
    updateTimer: "Uppdateringstimer",

    fields: {
      testId: "Test-ID",
      description: "Beskrivning",
      startTest: "Start",
      endTest: "Slut",
      active: "Aktiv",

      avgSnr: "Genomsnittlig SNR",
      avgRssi: "Genomsnittlig RSSI",

      data: {
        rssi: "RSSI",
        snr: "SNR",
        lat: "LATITUDE",
        lng: "LONGITUDE",
        createdAt: "TID",
        lastGateway: "GATEWAY",
      },
    },

    tab: {
      map: "MAP",
      data: "STATISTIK",
    },
  },

  trend: {
    res: {
      week: "Upplösning: Vecka",
      day: "Upplösning: Dag",
      hour: "Upplösning: 1 Timme",
      five: "Upplösning: 5 Timmar",
    },
  },

  notifications: {
    clearNotifications: "Markera alla som lästa",
    noNotifications: "Det finns inga nya notiser.",
  },

  landing: {
    slogan: "VISUALIZATION MADE EASY",
    or: "ELLER",
    key: {
      title: "Funktioner",
      1: "Nodhantering",
      2: "Visualisering",
      3: "Flera Dashboards",
      4: "Alarmhantering",
      5: "Personalhantering",
      6: "Skriptmotor",

      description: {
        1: "Hantera sensorer för datahantering.",
        2: "Få din sensordata visualiserad på det sätt du vill se din information.",
        3: "Skapa egna dashboards för ditt användningsområde.",
        4: "Alarmhantering för kritiska användningsområden.",
        5: "Hantera användarkonton för ditt företag.",
        6: "Avancerade användare kan skapa script för att manipulera data.",
      },
    },

    usecases: {
      title: "Applikation",
      1: "Miljösensorer",
      2: "Elsensorer",
      3: "Rörelsesensorer",
      4: "Temperatursensor",

      description: {
        1: "SENSORONLINE passar otroligt bra för smarta jordbrukslösningar så som de på Ljusgårda redan bygger.",
        12: "Läs mer om Ljusgårda.",
        2: "Mät ström för gatlyktor i din stad. Med skriptmotorn kan du göra så att datan blir lätt att tyda.",
        3: "Mät hur många människor som kommer och går från din anläggning.",
        4: "Självklart är temperatur-visualisering en nyckel för SENSORONLINE-platformen. Företag väljer att mäta och hantera sina temperaturer med SENSORONLINE.",
      },
    },

    help: {
      title: "KONTAKTA OSS",

      sensors: {
        title: "Behöver du några nya sensorer?",
        description:
          "Om du behöver hjälp med att lösa ett problem eller få mer kontroll på dina tillgångar. Eller hur du kan visualisera dina sensorer på ett sätt som underlättar för din verksamhet. Tveka inte att höra av dig till oss för att få hjälp. \n Du kan kolla vårt portfolio av ",
        store: "noder som vi säljer hos Nodeledge AB.",
      },

      api: {
        title: "Använd vårt API",
        description:
          "Du kan givetvis använda vårt API för att sätta in data eller hämta data från och till befintliga system. ",
      },

      contact: {
        title: "Är du intresserad? Ta kontakt!",
        siencepark: "Skövde Science Park",
        address: "Kaplansgatan 16B",
        postal: "521 32 Skövde",
        country: "Sverige",
        phoneTitle: "Telefon",
        phonenumber: "+46 (0) 500 600 022",
        phonenumber2: "+46 (0) 733 165 100",
        emailTitle: "Email",
        email: "info@sensor-online.se",
      },
    },
  },

  valueForm: {
    target: "Värde",

    actionType: "Typ",

    actionTypes: {
      color: "Färg",
      value: "Värde",

      desc: {
        colorBackground: "Bakgrundsfärg",
        colorBorder: "Borderfärg",
        colorText: "Textfärg",
        value: "Värde för värde",
      },
    },

    op: "Operation",
    ops: {
      eq: "<b>lika</b> än",
      notEq: "inte <b>lika</b> än",
      greater: "<b>större</b> än",
      less: "<b>mindre</b> än",
      greaterOrEq: "<b>större</b> eller lika än",
      lessOrEq: "<b>mindre</b> eller lika än",
    },
  },

  imageMap: {
    chooseTag: "Välj typ av markör",
    iconFormTitle: "Ikon",
    icon: {
      width: "Ikon Bredd",
      height: "Ikon Höjd",
      anchorx: "Ikon Offset Bredd",
      anchory: "Ikon Offset Höjd",
    },

    fontSelectorTitle: "Teckenstorlek",
    nodeSelection: "Nod",
    valueFormPanel: "Värden",

    colors: {
      background: "Bakgrund",
      border: "Border",
      text: "Text",
    },
  },

  log: {
    type: {
      sms: "SMS",
      app: "Applikation",
      warning: "Varning",
      error: "Error",
      userevent: "Userevent",
      login: "Login",
    },

    logTable: {
      title: "Logg",
    },

    activeAlarms: {
      title: "Aktiva larm",
    },

    activeUsersTable: {
      title: "Inloggade användare",
    },

    fields: {
      type: "Typ",
      user: "Användare",
      message: "Meddelande",
      createdAt: "Skapad",

      status: "Status",
      lastAction: "Event",
      lastSeen: "Timestamp",

      alarm: {
        name: "Namn",
        value: "Värde",
        messageSent: "Skickat notifikation",
      },
    },
  },

  common: {
    yes: "Ja",
    no: "Nej",
    on: "På",
    off: "Av",
    timestamp: "Tidsstämpel",
    lang: "Språk",
    error: "Ett fel uppstod försök igen senare",
    edit: "Redigera",
    delete: "Ta bort",
    reset: "Återställ",
    purge: "Radera",
    submit: "Skicka",
    create: "Skapa",
    update: "Uppdatera",
    updateAll: "Uppdatera alla",
    get: "Hämta",
    run: "Kör",
    address: "Adress",
    link: "Länk",
    height: "Höjd",
    width: "Bredd",
    new: "Ny",
    value: "Värde",
    newValue: "Nytt Värde",
    nothingToSee: "Inget att se här",
    next: "Nästa",
    label: "Label",
    editLabel: "Redigera Label",
    explanation: "Förklaring",
    never: "Aldrig",
    upload: "Ladda upp",
    click: "Klicka",
    required: "Krävs",

    hours: "Timmar",
    mintues: "Minuter",

    search: "Sök",
    close: "Stäng",
    cancel: "Avbryt",

    login: "Logga in",
    register: "Registrera dig",
    email: "Email",
    password: "Lösenord",
    confirmPassword: "Upprepa lösenord",
    newPassword: "Nytt lösenord",
    forgotPassword: "Glömt lösenord",

    createdAt: "Skapad",
    updatedAt: "Uppdaterad",

    save: "Spara",
    show: "Visa",
    updated: "Uppdaterad",
    created: "Skapad",
    deleted: "Raderad",
    back: "Tillbaka",
    send: "Skicka",
    refresh: "Uppdatera",
    preview: "Preview",
    previewSpacing: "Plats för andra komponenter",
    saveColor: "Spara färg",
    alarmColorPrecedence:
      "Larmfärger kommer alltid väljas före de manuellt valda färgerna, tvinga överskrivning i attributer om detta inte är vad som önskas.",

    add: "Lägg till",
    clear: "Rensa",
    success: "OK!",

    settings: "Inställningar",

    noTagOrAppChoosen: "Noder och/eller Applikation saknas",
    noDataFound: "Ingen data funnen",
    darkTheme: "Mörkt tema",

    active: "Aktiverat",
    notActive: "Avstängt",

    sent: "Skickat",
    notSent: "Inte Skickat",

    loginOrRegister: "Logga in eller registrera dig",

    or: "Eller",

    dashboard: "Gå till Dashboard",
    switchCompany: "Växla Konto",

    copy: "Kopiera",
    disagree: "Nej",
    verify: "Är du säker att du vill fortsätta?",
    verifyDelete: "Är du säker att du vill ta bort denna resurs?",

    accept: "Ja",

    registerCompany: "Skapa nytt konto",

    inactiveSensors: "Inaktiva sensorer",
    activeSensors: "Aktiva sensorer",
    sensorStatus: "Nod-status",
    sensorName: "Nod-namn",

    updatesPending: "Ändringar sker vid sidomladdning",
    pickLocationConfirm: "Vill du spara positionen på denna resurs?",

    getMorePages: "Ladda mer",

    nextStep: "Nästa",
    previousStep: "Tillbaka",

    toFewBytes: "För lite bytes",
    byteInputRequired: "Du måste fylla i detta fält",

    size: "Storlek",
    test: "Testa",

    select: "Välj",
    selected: "Vald",

    searchType: {
      TAG: "Noder",
      ALARM: "Alarm",
      APPLICATION: "Applikationer",
      DASHBOARD: "Dashboards",
      GATEWAY: "Gateways",
      SCRIPT: "Scripts",
    },

    dateLabel: "Datum",
    timeLabel: "Tid",

    time: {
      day: "Dag",
      hour: "Timma",
      quarterHour: "Kvart",
      minute: "Minut",
    },

    days: {
      monday: "Måndag",
      tuesday: "Tisdag",
      wednesday: "Onsdag",
      thursday: "Torsdag",
      friday: "Fredag",
      saturday: "Lördag",
      sunday: "Söndag",
    },

    dateRange: {
      0: "Senaste timmen",
      1: "Senaste 5 timmarna",
      2: "Senaste 24 timmarna",
      3: "Senaste 7 dagarna",
      4: "Senaste 30 dagarna",
    },

    staticinformation: {
      datapoints: "Datapunkter senaste 24 timmarna",
      inactiveSensors: "Inaktiva sensorer",
      activeAlarms: "Aktiva alarm",
      datapointsLastWeek: "Datapunkter under senaste veckan",
      weekday: "Veckodag",
    },

    welcome: "Hej, {user} !",

    errors: {
      getDecoders: "Kunde inte hämta decoders",
      backendConnection: "Ingen anslutning till backend kunde upprättas",
    },

    actions: {
      createCategory: "Skapa Kategori",
      editCategory: "Redigera Kategori",
      remove: "Ta bort",

      createDashboard: "Skapa Dashboard",
      copyDashboard: "Kopiera Dashboard",
    },

    iconSelector: {
      selectIcon: "Välj ikon",
      searchPlaceholder: "Sök på ikoner (ex. weather)",
    },
  },

  settings: {
    title: "Inställningar",
    userSettings: "Användarinställningar",
    themes: "Teman",

    company: {
      title: "Företagsinställningar",
      general: "Inställningar",
      branding: "White label",
      brandingTitle: "White Label titel",
    },

    data: {
      toolbarTransparancy: "Transparent toolbar",
      stickySideMenu: "Sticky sidmeny",
    },

    global: {
      name: "Globala inställningar",
      branding: "Märkning",
      powered_by: "Powered By",
      SMS_PROVIDER: "SMS Leverantör",
      SMS_IP1_FROM: "Avsändarnamn",
      SMS_IP1_USERNAME: "IP1 Användarnamn",
      SMS_IP1_PASSWORD: "IP1 Password",
      SMS_TWILIO_ACCOUNTSID: "Twilio AccountSID",
      SMS_TWILIO_ACCESSTOKEN: "Twilio AccessToken",
      SMS_TWILIO_NUMBER: "Twilio Number",
    },
  },

  companysetting: {
    field: {
      smsprovider: "SMS Leverantör",
      alarmTitle: "Larm",
      inactivityAlarmContact: "Mail för inaktivitets larm",
      nodeTitle: "Nod",
      nodeAlias: "Nodalias",
      mapTitle: "Kartainställningar",
      coordinates: "Latitude & Longitude i DD-format",
      companyLocation: "Hem position för företaget",

      hint: {
        inactivityAlarmContact:
          "Mailaddress för att få mail när sensorer har varit inaktiva i mer än 6 timmar",
        nodeAlias: "Detta hjälper till att föreslå och skapa nodnamnet",
      },

      ip1: {
        from: "Avsändarnamn",
        username: "IP1 Användarnamn",
        password: "IP1 AccessToken / Lösenord",
        hint: {
          from: "Detta är ett namn som kommer användas som avsändare av sms från SensorOnline",
          username:
            "Vill du hitta ditt användarnamn finns dokumentation här: https://docs.sensor-online.se",
          password:
            "Vill du hitta Access Token / Lösenord kan du se dokumentationen här https://docs.sensor-online.se",
        },
      },

      twilio: {
        number: "Twilio Nummer",
        accountSid: "Twilio AccountSID",
        accessToken: "Twilio AccessToken",
        hint: {
          number:
            "Detta är det nummer du vill skicka dina meddelanden från och som du har på Twilio",
          accountSid:
            "Du kan hitta ditt AccountSid genom att följa vår dokumentation här: https://docs.sensor-online.se",
          accessToken:
            "Du kan hitta din AccessToken genom att följa vår dokumentation här: https://docs.sensor-online.se",
        },
      },
    },
  },

  map: {
    create: "Skapa kart-dashboard",
    setPosFormTitle: "Välj sensor för position",
    currentPosition: "Nuvarande position",
    useNodePosition: "Använd nodens position",
    displayHistory: "Historiska positioner",
    dateTimeSpanSelection: "Datum att visa",
    dateFrom: "Start datum",
    dateTo: "Slut datum",
    clearPositions: "Rensa rutter",
    stepPositions: "Stega genom resan",
    select: {
      node: "Välj Nod",
      dashboard: "Välj Dashboard",
    },
    iconStyling: "Ikon-stil",
    livemap: {
      legend: {
        title: "Information",
        description: {
          1: "Nodens färg är baserad på dess RSSI och SNR värde.",
          2: "Klicka på en nod för en mer detaljerad beskrivning",
          3: "Gula eller röda noder innebär nödvändigtvis inget problem men då kommer batteriet att laddas ur fortfare",
        },
      },
    },
    picklocation: {
      legend: {
        title: "Information",
        description: {
          1: "The current position is marked on the map with a fixed marker.",
          2: "Klick on the map to pick a position. You may drag around the placed marker",
        },
      },
    },
    alltags: "Positions",
  },

  dashboard: {
    name: "Dashboards",
    createTitleClean: "Skapa ny Dashboard",
    copyDeleteTitle: "Ta bort eller kopiera Dashboard",
    createTitle: "Eller skapa ett nytt Dashboard",
    chooseTitle: "Välj Dashboard",
    changeTitle: "Byt Dashboard",
    selectDashboard: "Dashboards",
    navigationTitle: "Navigationsmeny",

    fields: { name: "Namn" },
    nameReq: "Detta fält måste vara ifyllt",

    dashboardType: {
      1: "Standard Dashboard",
      2: "Kart Dashboard",
      3: "Bild-kart Dashboard",
    },

    categories: {
      create: "Skapa Kategori",
      title: "Titel",
      icon: "Ikon",
    },

    copy: {
      tooltip: "Kopiera widget",
    },

    public: {
      title: "Publika dashboards",
      create: "Skapa en publik dashboard",
      createNew: "Skapa en ny publik dashboard",
      copiedSuccessful: "Kopierade länk till clipboard",
      copiedUnsuccessful: "Kunde inte kopiera {link}",
      URL: "Dashboard-URL",
      yes: "Ja",
      no: "Nej",
      remove: "Ta bort",

      fields: {
        host: "Ansvarig",
        expiresAt: "Giltig till",
        expired: "Aktiv",
        copyAction: "Länk",
        dashboardName: "Dashboard",
      },
    },
  },

  module: {
    noData: "Kunde inte hitta någon data",
    create: "Skapa ett nytt element",
    edit: "Redigera elementet",
    metas: "Attribut",

    colorFormTitle: "Färg inställningar",

    chart: {
      types: {
        line: "Linje",
        area: "Area",
        bar: "Stapeldiagram",
        scatter: "Spridningsdiagram",
        vertical: "Vertikal",
        trend: "Förändringsgraf",
      },
    },

    mapMarkers: {
      node: "Nål med Nodinformation",
      sensor: "Label med senast sensorvärde",
    },

    meta: {
      label: "Attribut",
      save: "Spara attribut",
      addSlider: "Lägg till slider",
      opacity: "Opacitet",

      paletteToModuleMode: "Ändra Modulfärger",
      paletteToCardMode: "Ändra Kortfärger",

      valueMatch: "Attribut 1",
      value: "Attribut 2",
      key: "Sensor",
      icon: "Ikon",

      styles: {
        text: {
          textcolor: "Textfärg",
          background: "Bakgrund - Innehåll",
          titleBackground: "Bakgrund - Titel",
        },

        single: {
          textcolor: "Textfärg",
          background: "Bakgrund - Innehåll",
          titleBackground: "Bakgrund - Titel",
          sparkline: "Sparkline",
          sparklineLabel: "Sparkline textfärg",
        },

        color: {
          cardBackground: "Kortbakgrund",
          titleBackground: "Titelbakgrund",
          titleText: "Titeltext",
          valueBackground: "Värdesbakgrund",
          valueText: "Värdestext",
        },
      },

      fontStyleTypes: {
        title: "Titel",
        value: "Värde",
        content: "Innehåll",
      },

      keys: {
        so_own_axis: "Egen Y-Axel",
        value_match: "Namn för värde",
        icon: "Ikon-suffix",
        sufix: "Suffix",
        prefix: "Prefix",
        deveui: "Nod",
        maxheight: "Maxhöjd",
        scriptid: "Skript-ID",
        target: "Börvärde",
        tagkey: "Sensor",
        tagkey_x: "Sensor X-led",
        tagkey_y: "Sensor Y-led",
        tagkey_x_max: "Sensor Max X-led",
        tagkey_y_max: "Sensor Max Y-led",
        forceColors: "Tvinga färger",
        decimal_rounding: "Decimalavrundning",
        dashboard_id: "Dashboard-ID",
        sync_y: "Synkronisera Y-axel",
        zoom_slider: "Zoom-reglage",
        digital: "Fyrkantsvåg",
        alarm_id: "Larmgränser",
        so_chart_type: "Diagramtyp",
        so_ignore_body: "Endast titel",
        min_value: "Minimumvärde",
        max_value: "Maxvärde",
        axis_label: "Axel-etikett",
        default_timespan: "Standardtidsspann",
        font_size: "Textstorlek",
        hide_trend_icon: "Göm Trend Ikon",
        horizontel_single_title: "Horisontell titel",
        so_hide_node_info: "Göm Nodinformation",
        font_style_large: "Typsnitt",
        so_single_sparkline: "Sparkline",
        gauge_180: "180°-visning",
        button_label: "Knapptext",
        button_label_on: "Text Påknapp",
        button_label_off: "Text Avknapp",
        hide_title: "Göm Titel",
        so_slots_count: "Antal Slots att visa",
        font_style_title: "Typsnitt för titel",
        font_style_content: "Typsnitt för innehåll",
      },

      desc: {
        forceColors:
          "Tvingar de valda färgerna att ha prioritet över larmfärger",
      },

      fields: {
        value_match: "Matchande värde",
        name: "Text",
        icon: "Ikon-suffix",
        sufix: "Suffix",
        prefix: "Prefix",
        deveui: "Nod",
        maxheight: "Maxhöjd (ex 300 för 300px)",
        scriptid: "Skript ID",
        target: "Börvärde",
        tagkey: "Sensor",
        tagkey_x: "Sensor X-led",
        tagkey_y: "Sensor Y-led",
        tagkey_x_max: "Sensor Max X-led",
        tagkey_y_max: "Sensor Max Y-led",
        forceColors: "Tvinga färger",
        decimal_rounding: "Antal Siffror",
        dashboard_id: "Dashboard-ID",
        sync_y: "Synkronisera Y-axel",
        so_own_axis: "Own Y-Axel",
        zoom_slider: "Visa zoom-reglage",
        digital: "Fyrkantsvåg",
        alarm_id: "Visa Larmgränser",
        chart_type: "Diagramtyp",
        ignoreBody: "Endast titel",
        min_value: "Minimumvärde",
        max_value: "Maxvärde",
        axis_label: "Axel-etikett",
        default_timespan: "Standardtidsspann",
        so_font_style_title: "Typsnitt för titel",
        so_font_style_content: "Typsnitt för innehåll",
        font_style_large: {
          type: "Typ",
          size: "Storlek",
          weight: "Tjocklek",
        },
      },
    },

    fields: {
      title: "Titel",
      types: "Typ",
      content: "Content",
      sm: "Storlek liten skärm",
      md: "Storlek medelstor skärm",
      min: "Min-värde",
      max: "Max-värde",
      image: "Bild",
      url: "URL",
      sync_chart: `Synkronisera X-axeln för alla dataserier
(när du har olika dataformat i samma graf)`,
    },

    enum: {
      size: { xs: "XSMALL", sm: "SMALL", md: "MEDIUM", lg: "LARGE" },
      types: {
        0: "Graf",
        1: "Visarinstrument",
        2: "Text",
        3: "Bild",
        4: "Karta",
        5: "Företagsinformation",
        6: "IFrame",
        7: "SMHI",
        8: "Senaste värdet",
        9: "Tabell rådata",
        10: "Graf",
        11: "Bild-karta",
        12: "Funktionsknapp",
        13: "Nod-info",
        14: "Förändringsgraf",
        15: "Nod-översikt",
        16: "360°-tiltvisare",
        17: "Fyllnadsgrad",
        18: "Börvärdessättare",
        19: "Kamera",

        desc: {
          0: "Visar en graf",
          1: "Visar ett visarinstrument",
          2: "Visar text",
          3: "Visar en bild",
          4: "Visar en karta",
          5: "Visar företagsinformation",
          6: "Visar IFrame",
          7: "Visar SMHI",
          8: "Visar det senaste värdet",
          9: "Visar rådata-tabell",
          10: "Visar en Graf",
          11: "Visar en bild-karta",
          12: "Visar en funktionsknapp",
          13: "Visar nod-information",
          14: "Visar en förändringsgraf",
          15: "Visar nod-översikt",
          16: "Visar en 360°-tiltvisare",
          17: "Visar en fyllnadsgrad",
          18: "Visar en börvärdessättare",
          19: "Visar en kamera ström",
        },
      },
    },

    functionButtonTypes: {
      script: "Skriptkörning",
      input: "Värdeinput",
      navigation: "Dashboard-länk",
      downlink: "Downlinks",
      onoffSwitch: "ON/OFF-switch",
    },
  },

  weather: {
    title: "SMHI Väderdata",
    icon: "Väder",
    temp: "Temperatur",
    windDir: "Vindriktning",
    windSpeed: "Vind",
    humidity: "Fukt",
    thunder: "Åska",
    date: "Datum",
    precipitation: "Nederbörd",
  },

  contact: {
    title: "Kontakta oss",
    descriptionTitle: "Sensor-Online",
    description:
      "Vi på SensorOnline vet att det kan vara svårt att komma igång med dina sensorer. Tveka inte att kontakta oss vid frågor eller för rådgivning.",
    emailTitle: "Email",
    email: "info@sensor-online.se",
    phoneTitle: "Telefon",
    phoneOne: "+46 (0) 500 6000 22",
    phoneTwo: "+46 (0) 733 165 100",
    locationSiencepark: "Skövde Science Park",
    locationStreet: "Kaplansgatan 16B",
    locationPostalcode: "521 32 Skövde",
    locationCountry: "Sweden",
  },

  company: {
    name: "Företag",
    orgNumber: "Organisationsnummer",
    associatedCompanies: "Anslutna företag",
    externalUsers: "Externa användare",

    companyInfo: "Företagsinformation",

    addUser: "Lägg till användare",

    invite: "Bjud in en användare",
    inviteSent: "Inbjudan skickad",
    inviteFailed:
      "Kunde inte hitta användaren. Se till att användaren är registrerad och inte redan tillhör ett företag!",

    shareApp: "Dela Applikation",

    whiteLabelLogo: "Företag logotyp",

    fields: {
      name: "Företagsnamn",
      orgNumber: "Organisationsnummer",
      postalAdress: "Postadress",
      deliveryAdress: "Leveransadress",
      contactPhonenumber: "Kontakt telefonnummer",
      invoiceAdress: "Epost för faktura",
      refrenceNmuber: "Referensnummer",
      image: "Företagslogotyp",
    },

    validation: {
      nameRequired: "Du måste fylla i Företagsnamn",
      nameMax: "Företagsnamn kan högst vara 255 tecken",

      orgNumberRequired: "Du måste fylla i Organisationsnummer",
      orgNumberFormatError: "Fel format på Organisationsnummer",

      emailRequired: "Du måste fylla i Email",
      emailFormatError: "Ange giltig Email-address",

      passwordMissmatch: "Lösenorden matchar inte",
    },
  },

  report: {
    title: "Rapporter",
    refrenceDateDesc:
      "Referensdagen är den dagen som refereras till vid utskick av rapport, om intervallen är månadsvis väljer du dagen som den ska skickas på(1:a varje månad till exempel). Eller veckovis (vilken dag mån - fre som rapporten ska skickas ut).",
    create: "Skapa Rapport-prenumeration",
    update: "Uppdatera Rapport-prenumeration",
    exportDownload: "Raportnedladdning",
    export: "Exportera",
    downloadFailed: "Det gick inte att hämta rapporten, försök igen senare.",
    noData: "Ingen data hittades i det valda tidsspannet",
    fields: {
      name: "Namn",
      type: "Typ",
      tempo: "Intervall",
      createdAt: "Skapad",
      updatedAt: "Uppdaterad",
      id: "ID",
      refrenceDate: "Referensdag",
      reportContacts: "Rapport-kontakter",
      lastSent: "Senast skickad",
    },

    tempo: {
      desc: {
        0: "Skickas varje dag med rapport för dagen.",
        1: "Skickas varje måndag med rapport för veckan innan.",
        2: "Skickas 1:a varje månad med rapport för månaden innan.",
      },

      0: "Dagligen",
      1: "Veckovis",
      2: "Månadsvis",
    },

    types: {
      regular: "Standard",
      trend: "Förbrukning",
    },
  },

  script: {
    name: "Skript",
    create: "Skapa nytt Skript",
    edit: "Redigera Skript",
    tagsTitle: "Nod-ID och Sensorer",
    placeHolderContent: `   // Convert the temperature to fahrenheit 
    var tempToFahrenheit = Data.Read("INSERT-DEVEUI", "INSERT-KEY") *  9/5 + 32;

    // Write the new value to a new key
    Data.Write("INSERT-DEVEUI", "INSERT-KEY", tempToFahrenheit);`,
    examples: "Exempel",
    helpTitle: "Exempel och hjälp",

    type: {
      0: "Loopat Skript",
      1: "Anropat Skript",
      2: "Triggat Skript",
    },

    fields: {
      name: "Namn",
      type: "Typ",
      interval: "Intervall",
      lastRun: "Senaste körning",
      enabled: "Aktiverat",
      boundTag: "Triggande tag",
    },

    validation: {
      intervalRequired: "Detta fält måste vara ifyllt",
      intervalMinValue: "Det minsta värdet för Intervall är 0",
    },
  },

  user: {
    name: "Användare",

    email: "Email",
    role: "Roll",

    userInfo: "Personliga uppgifter",

    loginFailed: "Inloggning misslyckades",
    signInFailed: "Email/lösenord matchar inte försök igen",
    signInError: "Oväntat fel inträffade under inloggning",
    loginSuccessful: "Inloggad",
    updateFailed: "Uppdateringen misslyckades",
    edit: "Redigera",
    forgotPassword: "Glömt lösenord?",

    fields: {
      firstName: "Förnamn",
      lastName: "Efternamn",
      phoneNumber: "Telefonnummer",
      email: "Email",
      password: "Lösenord",
      newPassword: "Nytt Lösenord",
      passwordConfirm: "Bekräfta Lösenord",

      phoneNumerHint:
        "Hint: Använd internationell formatering. Ex för Svenskt nummer (46703292943)",
    },
  },

  application: {
    name: "Applikation | Applikationer",
    noCompanyError: "För att skapa en Applikation måste du ha ett företag",
    applicationCreated: "En ny applikation har skapats",
    choose: "Välj en Applikation",
    create: "Skapa en ny Applikation",
    validation: {
      nameRequired: "Din applikation måste ha ett namn",
      nameMax: "Namn kan högst vara 255 tecken",
    },
    fields: {
      id: "ID",
      name: "Namn",
      createdAt: "Skapad",
      owner: "Ägare",
    },
  },

  alarm: {
    name: "Larm",
    create: "Lägg till nytt larm",
    affirmation: "Bekräfta",
    history: "Larmhistorik",

    activeAlarm: "Alarm-varningar",

    test: {
      title: "Test av inställningar",
      settings: "Testa mejl och sms inställningar",
      email: "Email adress",
      phonenumber: "Telefonnummer (46....)",
    },

    priority: {
      desc: {
        a: "Hög",
        b: "Medium",
        c: "Låg",
      },
    },

    alertType: {
      desc: {
        a: "Skickar E-post + SMS + Logg",
        b: "Skickar SMS + Logg",
        c: "Skickar E-post + Logg",
        d: "Skickar endast till Logg",
      },
    },

    fields: {
      id: "ID",
      name: "Namn",
      alertType: "Metod för larmavisering",
      priority: "Prioritetsnivå för larm",
      alarmDelay: "Fördröjning innan larm",
      autoAcknowledge: "Auto-Bekräftelse",
      active: "Larm På/Av",
      tagDataKey: "Sensor",
      tag: "Nod",
      message: "Larm-meddelande",
      limitHigh: "Hög larmgräns",
      limitLow: "Låg larmgräns",
      contactType: "Kontakttyp",
      alarmContacts: "Alarm-kontakter",
      activeDays: "Aktiva dagar",
      limit: "Låg och Hög larmgräns",
      activeAlarms: "Okvitterade Larm",
      messageSent: "Skickat meddelande",
      reset: "Kvitterat",
      triggered: "Utlöst",
      user: "Kvitterat av",
      value: "Utlösande värde",
      signalType: "Typ av Signal",
      digitalTrigger: "Utlös Vid",
      triggerHigh: "Hög Signal",
      triggerLow: "Låg Signal",
      advanced: "Avancerat larm",
      alarmComment: "Erkänn förnuftet",
      logicalOperator: "Operator",
    },

    validation: {
      nameRequired: "Detta fält måste vara ifyllt",
      max: "Maxgränsen är 255",
      min: "Mingränsen är ",
    },

    tabs: {
      triggered: "Utlösta larm",
      allAlarms: "Alla Larm",
      history: "Larmhistorik",
    },
  },

  batteryStatus: {
    levels: "Batterinivåer",
    value: "Värde",
    lastSeen: "Senaste värdet",
    label: "Batterispänning",
  },

  tagdata: {
    name: "Data",
    amount: "Antal datapunkter:",
    notation: "{0} st",
    latestactivity: "Senaste aktivitet",
    latestvalue: "Senaste värdet",
    defaultKey: "Standardnyckel",

    fields: {
      id: "ID",
      name: "Namn",
      key: "Sensor",
      value: "Värde",
      createdAt: "Skapad",
      label: "Label",
      tagDeveui: "Node",
      publishKey: "Publicera Sensor",
    },
  },

  tag: {
    sensor: {
      titile: "Sensorer",
      add: "Lägg till ny Sensor",
    },
    choose: "Sensor väljare",
    search: "Sök Nod",
    chooseDecoder: "Välj en decoder",
    decoder: "Decoder",
    name: "Noder",
    amount: "Antal noder:",
    notation: "{0} st",
    details: "Sensordetaljer",
    removeImage: "Ta bort bild",
    chooseKey: "Välj nyckel",
    saveKeyAsDefault: "Standard",
    newKey: "Ny Sensor",
    filter: "Filtrera Noder",
    generateId: "Generera ID",

    uploadExplanation:
      "Dra in din csv fil eller klicka här för att ladda upp Noder",
    uploadExplanationHint: "Du kan ladda ner en exempel fil på formatet här: ",
    exampleLink: "CSV Exempel",

    errorTogglePublishKey:
      "Något gick fel vid försök att växlande av publicering",

    publishing: {
      title: "Publicering",
      reset: {
        0: "Återställ MQTT Publicering",
        1: "Återställ Sparkplug Publicering",
        2: "Återställ Modbus Publicering",
        3: "Återställ OPC-UA Publicering",
      },

      mqtt: "MQTT",
      modbus: "Modbus",
      sparkplug: "Sparkplug",
    },

    tabs: {
      settings: "Inställningar",
      data: "Data",
      events: "Event Logg",
      sensors: "Sensorer",
    },

    createSteps: {
      step1: "Typ av Nod",
      step2: "Välj Application",
      step3: "Välj Decoder",
      step4: "Ange Nycklar",
      step5: "MQTT",
      step6: "Attribut",
      finish: "Klar",

      spotIo4: "SpotIO val",
      modbus4: "Modbus",
    },

    picker: {
      step1: "Välj en Nod",
      step2: "Välj en Sensor",
    },

    tagPool: {
      tagPool: "Nod-pool",
      deveui: "Deveui",
      createdAt: "Created",
      hide: "Göm från lista",
      take: "Lägg till sensor",
      show: "Visa i listan",
      hiddenTags: "Gömda noder",
    },

    fields: {
      id: "ID",
      name: "Namn",
      deveui: "DevEUI",
      appKey: "AppKey",
      appEui: "AppEUI",
      applicationId: "Applikation",
      profile: "Profil",
      lat: "Latitud",
      long: "Longitud",
      latAndLng: "Latitud och Longitud",
      image: "Bild på installation",
      battery: "Batterinivå",
      activeAlarm: "Larmar",
      activeData: "Aktiv",
      latestValue: "Senaste Värde",
      location: "Placering",
      dataPoints: "Datapunkter/24h",
      type: "Typ",
      nodeType: "Nod Typ",
    },

    nodeTypes: {
      0: "LORA",
      1: "API",
      2: "MQTT",
      3: "NR",
      4: "SPARKPLUG",
      5: "SpotIO",
      6: "Modbus",
    },

    attributes: {
      name: "Namn",
      value: "Värde",
      title: "Attribut",
    },
  },

  license: {
    usedGateways: "Totalt använda Gateways i Licensen",
    usedNodes: "Totalt använda Noder i Licensen",
    editMaxTitle: "Redigera gränser",
    companySettings: "Konto inställningar",
    licenseManagement: "Licens hantering",
    publicKey: "Publik nyckel",
    upload: "Ladda upp ny licens",
    uploadHint: "Dra din nya licens eller tryck för att välja licens-fil",
    license: "Licens",
    licensee: "Licenshavare",
    coveredFeatures: "Täckt Funktionalitet",
    fields: {
      email: "Email",
      name: "Namn",
      maxCompanies: "Max Antal Företag",
      decoderIncluded: "Decoder Inkluderad",
      whiteLabel: "White-Label",

      licenseId: "Licens ID",
      status: "Status",
      publicKey: "Publik Nyckel",
      maxGateways: "Max Antal Gateways",
      maxNodes: "Max Antal Noder",
      expires: "Löper ut",
    },

    limitations: {
      fields: {
        companyId: "Konto ID",
        companyName: "Konto",
        maxGateways: "Max Gateways (0 = Obegränsad)",
        maxNodes: "Max Noder (0 = Obegränsad)",
        updatedAt: "Uppdaterad",

        currentNodes: "Nuvarande Noder",
        currentGateways: "Nuvarande Gateways",
      },

      errors: {
        gateway:
          "Licensen måste utökas för att kunna lägga till fler Gateways, ta kontakt med din leverantör.",
        tag: "Licensen måste utökas för att kunna lägga till fler Noder, ta kontakt med din leverantör.",
        company:
          "Licensen måste utökas för att kunna lägga till fler Konton, ta kontakt med din leverantör.",
        expiresIn:
          "Licensen går ut om {0} dagar, ta kontakt med din leverantör.",
      },

      tos: {
        title: "Licenseavtal",
        description: "Läs licenseavtalet och acceptera för att logga in",
        accept: "Acceptera",
        checkbox: "Acceptera licenseavtal",
      },
    },

    errors: {
      couldNotSetLimits: "Kunde inte sätta limitationer för det valda kontot",
    },
  },

  validation: {
    required: "{0} måste vara ifylld",
    max: "{0} får max vara {1} tecken",
    requiredSingle: "Detta fält måste vara ifyllt",
    toLong: "Detta fält överstiger maxlängd",
    contryCodeError:
      "Glöm inte att telefonnumret måste ha en landskod (ex 4607xxxxxxxx)",
    passwordMissmatch: "Lösenorden matchar inte",
    deveuiConflict: "Nod med detta DevEUI finns redan på servern",
    passwordLength: "Lösenordet måste vara minst 6 karaktärer långt",
    passwordUpperAndLower:
      "Lösenordet måste innehålla minst en versal och minst en liten bokstav",
  },

  warning: {
    amount: "Aktiva Larm:",
    notation: "{0} st",
  },

  mqtt: {
    settingsTabTitle: "MQTT Brokers",
    brokerListTitle: "MQTT Brokers",
    createTitle: "Lägg till MQTT Broker",
    updateTitle: "Uppdatera MQTT Broker",
    selectBroker: "Välj en MQTT Broker",
    testBroker: "Testa anslutning",
    topicCreateTitle: "MQTT Topics",
    topicDescription:
      "Dem topics som är valda kommer prenumereras på genom den valda MQTT Broker. Varje topic ska vara specifik för Noden, om wildcards (+) används i topic bör det inte vara ett 'Multilevel Wildcard (#)', om det används kommer det ändras till ett vanligt wildcard.",
    topicAdd: "Lägg till en Topic",
    topicAlreadyExists: "Den valda Topicen finns redan på denna enheten",
    createTopicTitle: "Skapa en ny Prenumeration",

    sparkplug: {
      use: "Använd Sparkplug Publicering",
      meta: {
        hint: {
          edgeId: "Sparkplug edge id",
          groupId: "Sparkplug grupp id",
        },

        edgeId: "Edge ID",
        groupId: "Grupp ID",
        useNameForPublish: "Använd namn istället för NodID",
      },
    },

    meta: {
      fields: {
        topic: "Topic",
        publishTopic: "Publicerings Topic (Valfri)",
        publishTopicHint: "Kan inte vara samma Topic som en prenumeration",
        parsingTopic: "Dekoder typ",
        payloadTest: "Payload att testa",
        testTopic: "Förväntad topic i Decode funktionen",
      },
    },

    sslmodes: {
      0: "None",
      1: "CA Signed server certificate",
      2: "Self-signed server certificate",
    },

    versions: {
      0: "3.1.1",
      1: "3.1.0",
    },

    validation: {
      notfound: "Kunde inte hitta Broker",
    },

    fields: {
      status: "Status",
      hostname: "Hostnamn",
      port: "Port",
      version: "MQTT Version",
      sslmode: "SSL/TLS Mode",
      CACertificate: "CA Certificate",
      ClientCertificate: "Client Certificate",
      ClientKey: "Client Key",
      KeyPassPhrase: "Key Passphrase (Optional)",
      useAuth: "Använd Autentisering",
      username: "Användarnamn",
      password: "Lösenord",
      isGlobal: "Är Global Broker",

      metrics: {
        messages: {
          total: "Totalt antal Meddelanden",
          week: "Meddelanden denna Vecka",
          day: "Meddelanden denna Dag",
        },

        publish: {
          total: "Totalt antal Publicerade",
          week: "Publicerat denna Vecka",
          day: "Publicerat denna Dag",
        },
      },
    },
  },
};
